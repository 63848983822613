
function read_cookie(key)
{
    var result;
    return (result = new RegExp('(?:^|; )' + encodeURIComponent(key) + '=([^;]*)').exec(document.cookie)) ? (result[1]) : null;
}
window.vw = function(v) {
  var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  return (v * w) / 100;
}

$(document).on('turbolinks:load', function () {

  $(".popup-btn").on('click', function () {
    $(".home-popup-wrapper").hide();
    document.cookie = "confirmed= true;";

  })
  if (read_cookie("confirmed")) {
    $(".home-popup-wrapper").hide();
  } 
})

$(document).on('turbolinks:load', function () {

  $(".conferences-banner").on('click', function () {
    $(".popup-wrapper.conferences-wrapper").show();
    $("#conferences-popup").show();

  })
  $(".close-btn").on('click', function () {
    $(".popup-wrapper").hide();
    $(".popup").hide();

  })
    
    if ($("#linkedin-feed").height() > $("#homepagecontent").height() ) {
         $("#linkedin-feed").height($("#homepagecontent").height());
    }
    $(window).on("load", function(e) {
   
        $("#linkedin-feed").delay(500).height($("#homepagecontent").height());

    });
    $(window).on("resize", function(e) {
        $("#linkedin-feed").delay(500).height($("#homepagecontent").height());

    });

     
    

  
})
$(document).on('turbolinks:load', function () {
  $("#linkedin-feed a").on("click", function(e) {
    var href;
    var target = e.target || e.srcElement;
    href = target.href;
    e.preventDefault();
    $("#leavinghref").attr('href', href);
    $(".popup-wrapper.leaving-wrapper").show();
    $("#linkedin-popup").show();
  });
  $("#linkedin-popup .decline").on('click', function () {
    $(".popup-wrapper").hide();
    $(".popup").hide();

  })

});